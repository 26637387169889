const lazyLoading = (parrentSelector, mapSelector) => {
    const mapHeight = document.querySelector(parrentSelector).getBoundingClientRect().top,
        mapBlock = document.querySelector(mapSelector);
        if (mapHeight < 1000 && mapBlock) {
            mapBlock.setAttribute('src', mapBlock.getAttribute('data-src'));
            mapBlock.removeAttribute('data_src');
            window.removeEventListener('scroll', lazyLoading);
        }
};

export default lazyLoading;