const cardHiden = () => {
    const cards = document.querySelectorAll('.card'),
          showBtn = document.querySelector('.button-main_show button');
    cards.forEach((item, i) => {
        if (i > 5) {
            item.style.display = 'none';
        }
    });

    showBtn.addEventListener('click', () => {
        cards.forEach(item => item.style.display = 'flex');
        showBtn.style.display = 'none';
    })
}

export default cardHiden;