const progressbar = () => {
    const sections = document.querySelectorAll('#main section'),
        wrapperBar = document.createElement('div');



    document.body.append(wrapperBar);
    wrapperBar.classList.add('progressbar');



    function createLinks() {
        for (let index = 0; index < sections.length; index++) {
            const link = document.createElement('a');
            wrapperBar.append(link);
            link.classList.add('progressbar__item');
            link.setAttribute("href", `#${sections[index].getAttribute('id')}`);
        }
    }

    createLinks();

    let progressbarItems = document.querySelectorAll('.progressbar__item');
    function scroll() {
        sections.forEach((item, i) => {
            // console.log(document.getElementById('header').offsetHeight);
            if (item.getBoundingClientRect().top <= 40) {
                progressbarItems[i].classList.add('progressbar__item_active');
            } else {
                progressbarItems[i].classList.remove('progressbar__item_active');
            }
           
        });
    }


    scroll();

    window.addEventListener('scroll', scroll);
};



export default progressbar;