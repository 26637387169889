import progressbar from"./progressbar";
import lazyLoading from './lazyLoading';
import cardHiden from './cardHiden';

window.addEventListener("DOMContentLoaded", () => {
  window.addEventListener("scroll", () => {
    scrollAnimation(".loading", "post-loading");
    scrollAnimation(".up", "post-up");
    scrollAnimation(".info-card", "info-loading");
    lazyLoading('.map', '#lazymap');
  });

  progressbar();

  function scrollAnimation(scrollSelector, animationClass) {
    const scrollItem = document.querySelectorAll(scrollSelector);
    let windowCenter = window.innerHeight / 2 + window.scrollY;

    scrollItem.forEach((item) => {
      let scrollOffset = item.offsetTop;
      if (windowCenter >= scrollOffset) {
        item.classList.add(animationClass);
      }
    });
  }

  lazyLoading('.map', '#lazymap');

  cardHiden();

  
});

